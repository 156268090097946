export const ElegantTexIcon = () =>  (
    <svg width="240" height="50" viewBox="0 0 2039 604" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M342.836 480.981C233.405 480.981 144.693 392.271 144.693 282.84C144.693 173.413 233.405 84.6932 342.836 84.6932C412.333 84.6932 473.475 120.48 508.841 174.627C510.817 177.653 512.715 180.733 514.527 183.867H221.565V325.351H329.089V297.66H252.285V267.949H318.984V240.259H252.285V211.56H575.041C572.131 202.067 568.655 192.827 564.652 183.867C563.26 180.747 561.803 177.667 560.284 174.627C520.493 94.8266 438.065 40 342.836 40C208.721 40 100 148.733 100 282.84C100 416.955 208.721 525.675 342.836 525.675C355.711 525.675 368.349 524.675 380.681 522.744C383.784 522.259 386.865 521.715 389.927 521.113C400.567 519.02 410.96 516.235 421.052 512.804V269.029H464.104V240.329H346.875V269.029H389.927V475.351C386.872 476.097 383.789 476.769 380.681 477.371C368.432 479.741 355.779 480.981 342.836 480.981Z" fill="#00A874" />
        <path d="M645.768 244V72.312H752.635V95.0869H670.796V144.891H741.623V167.416H670.796V221.225H754.137V244H645.768Z" fill="white" />
        <path d="M782.637 244V72.312H807.664V220.724H878.742V244H782.637Z" fill="white" />
        <path d="M900.197 244V72.312H1007.06V95.0869H925.224V144.891H996.052V167.416H925.224V221.225H1008.57V244H900.197Z" fill="white" />
        <path d="M1098.63 247.003C1077.11 247.003 1060.51 239.745 1048.83 225.229C1037.15 210.547 1031.31 188.189 1031.31 158.156C1031.31 128.123 1037.48 105.849 1049.83 91.3328C1062.34 76.6501 1079.53 69.3087 1101.39 69.3087C1113.23 69.3087 1123.91 71.6446 1133.42 76.3164C1143.1 80.8213 1150.69 87.6621 1156.2 96.8388C1161.87 106.016 1164.71 117.528 1164.71 131.377H1138.93C1138.93 117.862 1135.34 107.934 1128.17 101.594C1121.16 95.0869 1112.15 91.8333 1101.14 91.8333C1087.29 91.8333 1076.53 96.9222 1068.85 107.1C1061.34 117.278 1057.59 132.211 1057.59 151.899V164.413C1057.59 184.268 1061.18 199.284 1068.35 209.462C1075.69 219.473 1086.37 224.478 1100.38 224.478C1113.23 224.478 1122.83 220.557 1129.17 212.716C1135.67 204.707 1138.93 193.111 1138.93 177.928V165.164L1149.69 175.174H1096.38V153.401H1164.71V244H1148.44L1143.43 224.979C1138.09 232.487 1131.59 238.077 1123.91 241.747C1116.24 245.251 1107.81 247.003 1098.63 247.003Z" fill="white" />
        <path d="M1179.65 244L1234.46 72.312H1265.74L1320.55 244H1293.77L1282.51 207.21H1215.93L1204.67 244H1179.65ZM1222.69 184.435H1275.5L1253.98 114.108C1253.48 111.772 1252.81 109.019 1251.97 105.849C1251.31 102.679 1250.72 99.9255 1250.22 97.5896C1249.89 95.0869 1249.72 93.8355 1249.72 93.8355H1248.72C1248.72 93.8355 1248.47 95.0869 1247.97 97.5896C1247.64 100.092 1247.14 102.929 1246.47 106.099C1245.8 109.269 1245.13 111.939 1244.47 114.108L1222.69 184.435Z" fill="white" />
        <path d="M1336.95 244V72.312H1358.98L1419.29 175.925C1420.46 177.927 1421.8 180.597 1423.3 183.934C1424.8 187.271 1426.05 190.274 1427.05 192.944C1428.22 195.447 1428.8 196.698 1428.8 196.698L1429.56 196.448C1429.56 196.448 1429.56 195.113 1429.56 192.443C1429.56 189.774 1429.47 186.854 1429.31 183.684C1429.14 180.347 1429.05 177.928 1429.05 176.426V72.312H1451.58V244H1430.06L1369.24 139.385C1368.24 137.216 1366.9 134.547 1365.23 131.377C1363.73 128.04 1362.4 125.036 1361.23 122.367C1360.06 119.697 1359.48 118.362 1359.48 118.362L1358.48 118.863C1358.48 118.863 1358.56 120.114 1358.73 122.617C1358.89 125.12 1359.06 127.956 1359.23 131.126C1359.4 134.296 1359.48 136.883 1359.48 138.885V244H1336.95Z" fill="white" />
        <path d="M1518.35 244V95.0869H1472.8V72.312H1588.93V95.0869H1543.63V244H1518.35Z" fill="white" />
        <path d="M679.055 491V342.087H633.505V319.312H749.632V342.087H704.332V491H679.055Z" fill="white" />
        <path d="M771.15 491V319.312H878.017V342.087H796.177V391.891H867.004V414.416H796.177V468.225H879.518V491H771.15Z" fill="#FED433" />
        <path d="M892.001 491L944.808 401.652L896.756 319.312H925.788L960.075 378.877H960.576L995.614 319.312H1022.14L974.09 400.15L1027.4 491H998.367L959.074 422.675H958.573L918.53 491H892.001Z" fill="white" />
    </svg>
)